<template>
  <div
    class="report-card"
    @click.prevent="
      tab === 'report-location'
        ? $router.push({ name: 'report-location' })
        : $router.push({
            name: 'report-stats',
            query: {
              tab,
            },
          })
    "
  >
    <h4 class="report-card-title">{{ title }}</h4>
    <div class="mt-4">
      <div class="relative">
        <span class="report-card-icon-box"></span>
        <span class="report-card-icon">
          <Icon
            :name="icon"
            :width="61"
            :height="61"
            :color="$attrs.iconColor"
            :textColor="$attrs.iconTextColor"
            style="width: 61px; height: 61px"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttributes: false,

  props: {
    title: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
    meta: {
      required: false,
      type: [Object, Array],
    },
    tab: {
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped></style>
