<template>
  <Layout mainClasses="bg-app-gray">
    <div class="mx-5 my-5 grid grid-cols-2 gap-4 relative">
      <Card
        v-for="data in reportsData"
        :key="data.tab"
        :title="data.title"
        :meta="data.meta"
        :icon="data.icon"
        :tab="data.tab"
        iconColor="transparent"
        iconTextColor="#7f7b7b"
      />
    </div>
  </Layout>
</template>

<script setup>
import { onMounted } from "vue";

import Layout from "../../components/home/Layout/Index";
import Card from "../../components/home/Reports/Card";
import useWidgetConfig from "@/modules/widget/composables/useWidgetConfig";

const { getContacts } = useWidgetConfig();

const reportsData = [
  {
    title: "Locations on Map",
    icon: "location-map",
    tab: "report-location",
  },
  {
    title: "Reports",
    icon: "stats",
    tab: "report-stats",
  },
];

onMounted(() => getContacts());
</script>
